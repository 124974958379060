.p-login-page {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;

  & .c-login-form {
    padding: 30px;
    border-radius: 20px;
    background-color: cadetblue;
    display: flex;
    flex-direction: column;

    &__text {
      margin-bottom: 30px;
    }

    & input {
      margin: 10px 0;
    }
  }
}