.pr-work-order {
  display: none;
}

@media print {
  .pr-work-order {
    display: block;
    font-size: 10pt;
    font-family: Arial, Helvetica, sans-serif;

    & &__title {
      font-family: Arial, Helvetica, sans-serif;
      text-align: center;
      padding: $padding-large;
      font-size: 24pt;
      font-weight: bold;
    }
  }
}
