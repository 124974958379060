/*----------------------------------------BREAKPOINTS MIXIN----------------------------------------*/
@mixin breakpoint($breakpoint) {

    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {

        // Prints a media query based on the value
        @media #{inspect(map-get($breakpoints, $breakpoint))} {
            @content;
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}





/*----------------------------------------------------*/

@mixin spinner {
    background-image: svg-spinner(#049ff8, #4721f0);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

@mixin input-spinner {
    @include spinner();
    background-position: right;
    padding-right: 30px; //TODO: set proper measures for spinner
}

@mixin pagesBackground {
    width: 100%;
    height: 100vh;
    background: radial-gradient($primaryDark, $secondaryDark);
}

@mixin navigationText {
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
    font-weight: 600;
    margin-left: 1.5rem;
    @include baseTransition
}

@mixin CardProperty {
    box-shadow: 0.15rem 0.15rem 0.35rem black;
    border-radius: 0.5rem;
    background: linear-gradient(to bottom, $secondaryLight, $primaryLight);
}

@mixin c-c {
    width: 100%;
    height: 100%;

}

@mixin AligmentFlexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin HeadingFontSizeXL {
    font-size: 3rem;
    letter-spacing: 0.2rem;
    font-weight: 900;
}

@mixin HeadingFontSizeL {
    font-size: 2rem;
    letter-spacing: 0.2rem;
    font-weight: 800;
}

@mixin HeadingFontSizeM {
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
    font-weight: 700;
}

@mixin HeadingFontSizeS {
    font-size: 1rem;
    letter-spacing: 0.15rem;
    font-weight: 600;
}


@mixin HeadingFontSizeXS {
    font-size: 0.7rem;
    letter-spacing: 0.15rem;
    font-weight: 500;
}

@mixin TextShadow {
    text-shadow: 0.06rem 0.06rem 0 $primaryLight;
}

@mixin baseTransition {
    transition: all 0.4s ease-in-out;
}
