.c-button {
  width: unset;
  min-width: 150px;
  border: 2px solid transparent;
  border-radius: 6px;
  padding: $padding-medium;
  cursor: pointer;
  color: $color--dark--text;
  background-color: $color--primary;

  &:hover {
    border: 2px solid $color--input-border;
  }

  //modifiers
  &--success {
    background-color: $color--success;
  }

  //state
  &.is-disabled {
    background-color: $color--shade;
  }
}

@media print {
  .c-button {
    display: none;
  }
}

input[type="button"],
input[type="submit"],
button {
  @extend .c-button; // set global styling for buttons
}
