.c-checkbox-group {
    display: flex;
    flex-direction: row;

    &--vertical {
        flex-direction: column;
    }

    & &__checkbox {
        //transform: scale(1.5);
        width: 20px;
        height: 20px;
        cursor: pointer;
    }


}