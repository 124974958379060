//CONTAINER
.u-container--primary {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 2%;
  padding-bottom: 2%;
}

.u-container--secondary {
  padding: $padding-large;
}

@media print {
  .u-container--primary {
    padding-left: 1cm;
    padding-right: 1cm;
    padding-top: 1cm;
  }
}

//FLOAT
.u-float--right {
  float: right;
}

.u-float--left {
  float: left;
}

//DISPLAY
.u-display--inline {
  display: inline;
}

.u-display--inline-block {
  display: inline-block;
}

.u-display--block {
  display: block;
}

.u-display--flex {
  display: flex;
}

.u-display--inline-flex {
  display: inline-flex;
}

.u-display--contents {
  display: contents;
}

//BORDER
.u-border--none {
  border: none;
}

.u-border--1px-gray {
  border: 1px solid $indicatorGrey;
}

.u-border-bottom--1px-gray {
  border-bottom: 1px solid $indicatorGrey;
}


.u-no-bottom-border{
  border-bottom: 0px !important;
}

//PADDING
.u-padding--0 {
  padding: 0px;
}

.u-padding--10 {
  padding: 10px;
}

//MARGIN
.u-margin--10 {
  margin: 10px;
}

.u-margin--top-5 {
  margin-top: 5px;
}

.u-margin--top-10 {
  margin-top: 10px;
}

.u-margin--top-20 {
  margin-top: 20px;
}

.u-margin--top-40 {
  margin-top: 40px;
}

.u-margin--top-80 {
  margin-top: 80px;
}

.u-margin--right-10 {
  margin-right: 10px;
}

.u-margin--right-5 {
  margin-right: 5px;
}

.u-margin--right-20{
  margin-right: 20px;
}

//JUSTIFY
.u-justify-content--space-between {
  justify-content: space-between;
}

.u-justify-content--space-around {
  justify-content: space-around;
}

.u-justify-content--flex-start {
  justify-content: flex-start;
}

.u-justify-content--center {
  justify-content: center;
}

//FLEX DIRECTION
.u-flex-direction--row {
  flex-direction: row;
}

.u-flex-direction--column {
  flex-direction: column;
}

.u-text--dark {
  color: $color--dark--text;
}

.u-prevent-scroll {
  height: 100%;
  overflow: hidden;
}

//FONT
.u-font--bold {
  font-weight: 700;
}

.u-text-vertical {
  transform: rotate(270deg);
}

.u-font-10 {
  font-size: 10pt;
}

//POSITION

.u-position--absolute {
  position: absolute;
}

.u-position--relative {
  position: relative;
}

//TEXT ALIGN

.u-text-align--center {
  text-align: center;
}

.u-text-align--left {
  text-align: left;
}

//ALIGN ITEMS

.u-align-items--center {
  align-items: center;
}

.u-display--flex-end {
  align-items: flex-end;
}

.u-display--flex-start {
  align-items: flex-start;
}

//BACKGROUND

.u-background-lightgray{
   background-color: lightgray;
}
