.pr-dailyoverview {
  display: none;
}

@media print {
  .pr-dailyoverview {
    display: block;
    font-size: 8pt;
    font-family: Arial, Helvetica, sans-serif;

    & &__title {
      font-family: Arial, Helvetica, sans-serif;
      text-align: center;
      padding: $padding-large;
      font-size: 20pt;
      font-weight: bold;
    }

    & &__table-header-item {
      border: 1px solid black;
      justify-content: center;
      align-items: center;
      text-align: center; 
    }



    & &__table-header {
      display: flex;
      flex-direction: row;
      & * {
        display: flex;
      }
    }

    & &__table-row {
      display: flex;
      flex-direction: row;

      & * {
        display: flex;
        padding-left: 5px;
      }
    }

    & * {
      flex: none;
    }

    & &__table-body-item {
      border: 1px solid black;
    }

    & .width-50px {
      width: 50px;
    }

    & .width-70px {
      width: 70px;
    }
    & .width-80px {
      width: 80px;
    }

    & .width-100px {
      width: 100px;
    }
    & .width-150px {
      width: 150px;
    }
    & .width-200px {
      width: 200px;
    }
    & .width-350px {
      width: 350px;
    }

    & .width-300px {
      width: 300px;
    }
    & .width-400px {
      width: 400px;
    }


    & .height-120px {
      height: 160px;
    }

    & .height-100px {
      height: 130px;
    }

    & .height-80px {
      height: 100px;
    }

    & .height-50px {
      height: 50px;
    }

  }
}
