.p-work-order {
  min-height: 95vh;

  & &__general-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @include breakpoint("md") {
      justify-content: space-around;
      flex-direction: row;
    }
  }

  & &__form-group {
    width: 300px;

    & input {
      width: 100%;
    }
  }
}



//PRINT
@media print {
  .p-work-order {
    background: white;
  }
}