.c-table-filter {
  margin-bottom: 10px;

  & select {
    margin-left: 10px;
  }
  & label {
    margin-right: 10px;
  }
}
