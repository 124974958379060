.c-collapsable-panel {
  height: 100%;
  border-radius: 8px;
  border: 2px solid $color--dark;
  margin-bottom: 10px;
  padding: 10px;

  & &__body {
    transition: max-height 0.2s ease-out;
    height: min-content;
    max-height: 100vh;
  }

  &.collapsed {
    height: 100%;

    & .c-collapsable-panel__body {
      overflow: hidden;
      transition: max-height 0.2s ease-out;
      max-height: 0;
    }

    & .c-collapsable-panel__header-text::after {
      transform: translateY(0px) translateX(15px);
    }
  }

  &__header {
    border-radius: 8px;
    position: relative;
    cursor: pointer;

    &-text::after {
      content: " ";
      display: inline-block;

      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid currentColor;

      vertical-align: middle;
      margin-right: 0.7rem;

      transform: rotate(90deg) translateX(0px) translateY(-15px);
      transition: transform 0.2s ease-out;
    }
  }
}