.c-advanced-search {

    &__form-group-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }


    &__form-group {
        margin: 10px;
        width:300px;
        
        & input{
            width:300px;
        }
    }
}