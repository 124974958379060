.c-smoke-screen {
    display: block;
    position: fixed;
    background-color: rgba($color: #000000, $alpha: 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    z-index: 1;

    &--md {
        @include breakpoint("md") {
            display: none;
        }
    }
}