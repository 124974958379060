.pr-record {
  display: none;
}

@media print {
  .pr-record {
    display: block;
    font-size: 10pt;
    font-family: Arial, Helvetica, sans-serif;


    & &__title {
      font-family: Arial, Helvetica, sans-serif;
      text-align: center;
      padding: $padding-large;
      font-size: 24pt;
      font-weight: bold;
    }

    & &__table-header-item {
      border: 1px solid black;
      justify-content: center;
      align-items: center;
    }

    & &__table-header {
      display: flex;
      flex-direction: row;
      & * {
        display: flex;
      }
    }

    & &__table-row {
      display: flex;
      flex-direction: row;

      & * {
        display: flex;
      }
    }

    & * {
      flex: none;
    }

    & &__table-body-item {
      border: 2px solid black;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    & .height-30px {
      height: 30px;
    }
    & .height-60px {
      height: 60px;
    }

    & .width-50px {
      width: 50px;
    }
    & .width-100px {
      width: 100px;
    }
    & .width-150px {
      width: 150px;
    }
    & .width-170px {
      width: 170px;
    }
    & .width-200px {
      width: 200px;
    }
    & .width-300px {
      width: 300px;
    }
    & .width-350px {
      width: 350px;
    }

    & .height-120px {
      height: 160px;
    }

    & .height-100px {
      height: 130px;
    }

    & .height-80px {
      height: 100px;
    }

    & .height-20px {
      height: 30px;
    }
  }
}
