.c-hamburger {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 40px;
    max-height: 40px;
    width: 36px;
    height: 36px;
    padding: 2px;
    overflow: hidden;
    cursor: pointer;

    & .c-hamburger__bar {
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        display: block;
        width: 33px;
        height: 4px;
        margin-bottom: 6px;
        position: relative;

        background: white;
        border-radius: 3px;

        z-index: 1;

        transform-origin: 4px 0px;

        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
            opacity 0.55s ease;

        &--1 {
            transform-origin: 0% 0%;
            margin-top: 5px;
        }

        &--2 {
            transform-origin: 0% 100%;
        }

        &--3 {
            transform-origin: 0% 100%;
        }
    }

    &.is-expanded {
        & .c-hamburger__bar {
            position: absolute;
            margin: 0;
            left: 7px;

            &--1 {
                transform: rotate(45deg);
                top: 4px;
            }

            &--2 {
                opacity: 0;
                transform: rotate(0deg) scale(0.2, 0.2);
            }

            &--3 {
                transform: rotate(-45deg);
                bottom: 6px;
            }
        }
    }
}