.c-form-input {
  display: block;
  position: relative;

  &--block {
    display: block;
  }

  & .c-form-input__label {
    color: $color--dark--text;
    margin-top: auto;
    margin-right: 10px;
  }

  & &__input-wrapper {
    display: inline-block;
    position: relative;
  }

  & .c-form-input__element {
    &--textarea {
      font-size: 16px;
      resize: vertical;
      width: 100%;
      min-height: 4em;
      max-height: 8em;
    }

    &--select {
      font-size: 0.9rem;
      font-family: inherit;
      padding-left: 0px;
    }

    & option {
      font-size: 0.9rem;
      font-family: inherit;
    }

    &.is-valid {
      border-color: #7ec117;
      border-width: 2px;
    }

    &.is-invalid {
      border-color: red;
      border-width: 2px;
    }

    &.is-loading {
      @include input-spinner;
    }
  }

  &--block {
    & .c-form-input__label {
      display: block;
    }

    & .c-form-input__input-element {
      display: block;
    }
  }

  &__autocomplete {
    visibility: hidden;
    z-index: 2;
    max-height: 6em;
    min-width: 150px;
    overflow: hidden;
    overflow-y: scroll;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: rgb(224, 223, 223);
    border: 1px solid rgb(199, 197, 197);
    border-radius: 2px;

    &.is-active {
      visibility: visible;
    }

    //firefox mozilla
    scrollbar-color: rgb(0, 0, 0) rgb(253, 253, 253);
    scrollbar-width: thin;

    //scroll bar
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 15px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(rgb(253, 253, 253), 0.2);
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(rgb(253, 253, 253), 0.4);
      border-radius: 0px;
      height: 70px;
      min-height: 60px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgb(0, 0, 0);
    }
  }

  &__autocomplete::-webkit-scrollbar {
    width: 5px;
  }

  &__autocomplete-item {
    padding: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    background-color: white;
    color: $color--dark--text;

    &:hover {
      background-color: $color--primary;
      color: $color--light--text;
    }
  }

  &__error-msg {
    font-size: 11px;
    color: red;
  }
}

@media print {
  .c-form-input__input-element {
    border: none !important;
  }
}

.c-digit-input {
  width: 40px;
}