.c-form {
  border: 2px solid $color--dark;
  min-width: 320px;
  border-radius: 5px;
  background-color: $color--background;
  overflow: hidden;

  &__header {
    padding: $padding-medium;
    background-color: $color--dark;
    color: $color--light--text;
    width: 100%;
  }

  &__body {
    padding: $padding-large;
  }

  &__input {
    width: 100%;

    & label {
      display: inline-block;
      width: 150px;
    }

    & .c-form-input__element {
      width: 250px;
      background-color: $color--secondary;
    }

    &--select {
      width: 250px;
    }
  }
}
