.c-form-autocomplete {
  position: relative;

  &__input {
    padding-right: 30px;
    background-position-x: 98%;
    background-position-y: center;
    background-size: 20px;
    background-repeat: no-repeat;
  }
  
  &.is-searching {
    .c-form-autocomplete__input {
      background-image: url('../../assets/cancel.svg');
    }
  }
  &.is-selected {
    .c-form-autocomplete__input {
      background-image: url('../../assets/check.svg');
    }
  }

  &.is-loading {
    .c-form-autocomplete__input {
      @include input-spinner;
    }
  }

  &.is-active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 100px;
    left: 50px;
    right: 50px;
    //bottom: 100px;
    z-index: 1;

    .c-form-autocomplete__input {
      z-index: 1;
    }

    &::before {
      content: attr(data-label);
      z-index: 2;
      // position: absolute;
      // bottom: 10px;
    }

    @include breakpoint("md") {
      display: block;
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      width: auto;
      height: auto;

      &::before {
        content: none;
        display: none;
      }
    }
  }

  &__options {
    position: static;
    z-index: 2;
    max-height: 6em;
    min-width: 150px;
    overflow: hidden;
    overflow-y: scroll;
    top: 100%;
    left: 0;
    right: 0;
    background-color: rgb(224, 223, 223);
    border: 1px solid rgb(199, 197, 197);
    border-radius: 2px;

    @include breakpoint("md") {
      position: absolute;

      &--above {
        top: auto;
        bottom: 100%;
      }
    }

    //firefox mozilla
    scrollbar-color: rgb(0, 0, 0) rgb(253, 253, 253);
    scrollbar-width: thin;

    //scroll bar
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 15px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(rgb(253, 253, 253), 0.2);
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(rgb(253, 253, 253), 0.4);
      border-radius: 0px;
      height: 70px;
      min-height: 60px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgb(0, 0, 0);
    }
  }

  &__autocomplete::-webkit-scrollbar {
    width: 5px;
  }

  &__option {
    padding: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    background-color: white;
    color: $color--dark--text;

    &:focus {
      background-color: $color--primary;
      color: $color--light--text;
    }
  }
}