.c-modal__provider {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 100;

  .c-modal--viewport-height {
    & > .c-modal__body {
      max-width: 95vw;
      max-height: 95vh;
      overflow: auto;
    }
  }
}
