@font-face {
  font-family: "OpenSans";
  font-display: swap;
  font-weight: 300;
  src: url("../../fonts/OpenSans-Light.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSans";
  font-display: swap;
  font-weight: 400;
  src: url("../../fonts/OpenSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSans";
  font-display: swap;
  font-weight: 600;
  src: url("../../fonts/OpenSans-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSans";
  font-display: swap;
  font-weight: 700;
  src: url("../../fonts/OpenSans-Bold.ttf") format("truetype");
}
