.c-files-uploader {
    display: block;

    &__label {
        min-width: 80px;
        display: block;
    }

    &__input {
        display: none;
    }

    &__file {
        padding: 5px;
        border: 1px dashed black;

        &-remove {
            display: inline-block;
            padding: 10px;
            border: 1px solid red;
            margin-left: 10px;
            cursor: pointer;
        }
    }
}