.c-dialog-box {
  border: 2px solid $color--dark;
  border-radius: 10px;
  background-color: $color--secondary;
  overflow: hidden;

  .c-dialog-box__header {
    padding: 10px 20px;
    width: 100%;
    background-color: $color--primary;
  }

  .c-dialog-box__body {
    padding: 10px 20px;
    width: 100%;
  }

  .c-dialog-box__controls {
    display: flex;
    margin-top: 20px;
    justify-content: space-around;
  }
}
