.c-table {
  width: 100%;
  height: min-content;

  @include breakpoint("md") {
    border-collapse: revert;
    border-radius: 8px 8px 0 0;
    border: 2px solid $color--dark;
  }

  & thead {
    display: none;

    @include breakpoint("md") {
      display: table-header-group;
    }
  }

  &__body-row {
    display: block;
    cursor: pointer;
    margin-bottom: 20px;

    &:nth-child(2n + 1) {
      background-color: $color--background;
    }

    @include breakpoint("md") {
      display: table-row;
      margin: auto;

      &:hover {
        background-color: $color--secondary;
      }
    }

    background-color: rgba($color--background, 0.1);

    //state
    &.is-clickable {
      cursor: pointer;
    }

    &.is-selected {
      background-color: $color--primary;
    }
  }

  & th {
    border-bottom: 1px solid $color--dark;
    background-color: $color--dark;
    color: $color--light--text;
    padding: $padding-medium;

    &:first-child {
      border-radius: 5px 0 0 0;
      border-left: 1px solid $color--dark;
    }

    &:last-child {
      border-radius: 0 5px 0 0;
      border-right: none;
    }
  }

  & td {
    display: flex;
    position: relative;
    border-bottom: 1px solid lightblue;
    word-break: break-word;
    padding-right: 10px;

    &::before {
      content: attr(data-label);
      font-weight: bold;
      text-transform: uppercase;
      width: 40%;
      min-width: 40%;
      max-width: 40%;
      text-align: left;
      font-size: 12px;
      background-color: lightblue;
      border-bottom: 1px solid white;
      display: flex;
      padding: 5px 0;
      padding-left: 10px;
      align-items: center;
      margin-right: 10px;
      word-wrap: break-word;
    }

    @include breakpoint("md") {
      display: table-cell;
      text-align: left;
      border-left: 1px solid $color--dark;
      border-bottom: 1px solid $color--dark;
      padding: $padding-small;
      word-break: normal;

      &:last-child {
        border-right: 1px solid $color--dark;
      }

      &::before {
        content: "";
        display: none;
      }
    }

    color: $color--dark--text;
  }
}
