* {
  box-sizing: border-box;
}

html,
body {
  font-size: 16px;
  font-family: "OpenSans";
  background-color: $color--base;
  height: 100%;
}

h1 {
  text-align: center;
  padding: $padding-large;
  font-size: 1.5rem;
  font-weight: bold;
}

button,
input,
select {
  font-size: 0.9rem;
  font-family: inherit;
}

input,
select {
  border: 2px solid $color--dark;
  color: $color--dark--text;
  background-color: rgba(white, 0.9);
  border-radius: 4px;
  padding: $padding-small;

  &:focus {
    border-color: $color--darker;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
}

fieldset {
  border: 1px groove $color--dark;
  border-color: $color--dark;
  padding: 15px;
}