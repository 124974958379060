.c-pagination {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.c-pagination-button {
  cursor: pointer;
  width: 50px;
  padding: 5px;
  border: 1px solid black;
  border-radius: 8px;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  background-color: $color--base;

  &.is-selected {
    cursor: default;
    color: white;
    background-color: $color--dark;
    &:hover {
      background-color: $color--dark;
    }
  }

  &.is-disabled {
    cursor: default;
    background-color: $indicatorGrey;

    &:hover {
      background-color: $indicatorGrey;
    }
  }

  &:hover {
    background-color: $color--primary;
  }
}
