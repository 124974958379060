.c-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 3;
  width: 100%;
  background-color: $color--darker;
  padding: 0px 30px 0px 60px;
  height: 64px;

  @include breakpoint("md") {
    padding: 20px;
    width: 200px;
    height: 100vh;
    flex-direction: column;
    justify-content: baseline;
    overflow-x: hidden;
    overflow-y: auto;
    float: left;
  }

  // &.is-expanded {

  // }

  &__menu {
    max-height: 80vh;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    transition: left ease-in-out 600ms;
    top: 100%;
    left: -100%;
    background-color: inherit;

    &.is-expanded {
      left: 0px;
    }

    @include breakpoint("md") {
      position: initial;
      width: 100%;
      transition: initial;
      top: initial;
      left: initial;
      overflow: visible;
      margin-right: 10px;

      order: 2;

      &.is-expanded {
        left: initial;
      }
    }
  }

  &__link {
    display: inline-flex;
    align-items: center;
    color: $color--light--text;
    text-decoration: none;
    font-weight: $font-semibold;
    justify-content: center;
    width: 100%;
    height: 64px;
    border-top: 1px solid rgba($color: #ffffff, $alpha: 0.3);

    @include breakpoint("md") {
      height: 100%;
      width: 100%;
      padding: 10px 10px;
      //border-right: 1px solid rgb(151, 148, 148);
      font-size: 16px;
      display: block;

      &:first-child {
        //border-left: 1px solid rgb(151, 148, 148);
      }

      &.is-active {
        background-color: $color--base;
        //border-left: 1px solid rgb(151, 148, 148);
        border-width: 2px;
        color: black;

        &:first-child {
          //border-left: 2px solid rgb(151, 148, 148);
        }
      }

      &:hover {
        background-color: rgba($color--background, 0.3);
        color: $color--dark--text;
      }
    }
  }

  & &__user {
    display: none;

    @include breakpoint("md") {
      margin-bottom: 40px;
      display: flex;
      order: 1;
      flex-direction: column;
    }
  }

  & &__hamburger {
    position: absolute;
    right: 25px;
    top: 15px;
  }

  & &__logout {
    position: absolute;
    right: 80px;
    top: 15px;

    @include breakpoint("md") {
      position: static;
      right: initial;
      top: initial;
      order: 4;
      text-align: center;
    }
    &-img{
      width: 32px;
      height: 32px;
      cursor: pointer;
      margin-top: 5px;
    }
    &-text{
      display: none;

      @include breakpoint("md") {
        display: block;
        color: #f0faf8;
        text-decoration: none;
        font-weight: 600;
        padding: 0px 10px 0px 00px;
        font-size: 16px;
      }
    }
  }

  &__language {
    @include breakpoint("md") {
      order: 3;
    }
  }

  // & &__controls {
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;

  //   @include breakpoint("md") {
  //     justify-content: end;
  //   }
  // }
}

.c-user-info {
  display: flex;
  flex-direction: row;
  align-items: center;


  &__avatar {
    width: 30px;
    height: 30px;
    margin-right: 10px;

    @include breakpoint("md") {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  &__user {
    color: white;
    font-size: 14px;
  }
}

@media print {
  .c-navbar {
    display: none;
  }
}