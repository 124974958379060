.c-language-select {
  position: relative;
  text-align: center;

  &__text {
    display: none;

    @include breakpoint("md") {
      display: block;
      color: #f0faf8;
      text-decoration: none;
      font-weight: 600;
      padding: 0px 10px 0px 00px;
      font-size: 16px;
    }
  }

  &__selected {
    display: inline-block;
  }

  & &__options {
    position: absolute;
    background-color: black;
    border: 1px solid white;
    margin-top: -5px;
    margin-left: -10px;
    padding: 10px;
    padding-bottom: 0px;
    text-align: center;

    &--above {
      @include breakpoint("md") {
        bottom: 0px;
      }
    }

    &--under {
      @include breakpoint("md") {
        left: calc(50% - 15px);
        top: 100%;
      }
    }
  }

  & &__option {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}