 .pr-release-to-service {
   display: none;
 }

@media print {
  .pr-release-to-service {
    display: block;
    font-size: 10pt;
    font-family: Arial, Helvetica, sans-serif;


    & &__title {
      font-family: Arial, Helvetica, sans-serif;
      text-align: center;
      padding: $padding-large;
      font-size: 24pt;
      font-weight: bold;
    }

    & &__table-header-item {
      border: 1px solid black;
      justify-content: center;
      align-items: center;
    }

    & &__table-header {
      display: flex;
      flex-direction: row;
      & * {
        display: flex;
      }
    }

    & &__table-row {
      display: flex;
      flex-direction: row;

      & * {
        display: flex;
      }
    }

    & * {
      flex: none;
    }

    & &__table-body-item {
      border: 2px solid black;
      border-left: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & &__table-body-item-first {
      border: 2px solid black;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & .height-30px {
      height: 30px;
    }
    & .height-40px {
      height: 40px;
    }
    & .height-60px {
      height: 60px;
    }
    & .height-80px {
      height: 80px;
    }
    & .height-120px {
      height: 120px;
    }
    & .height-180px {
      height: 180px;
    }

    & .width-50px {
      width: 50px;
    }
    & .width-80px {
      width: 80px;
    }
    & .width-90px {
      width: 90px;
    }
    & .width-100px {
      width: 100px;
    }
    & .width-120px {
      width: 120px;
    }
    & .width-140px {
      width: 140px;
    }
    & .width-150px {
      width: 150px;
    }
    & .width-170px {
      width: 170px;
    }
    & .width-190px {
      width: 190px;
    }
    & .width-200px {
      width: 200px;
    }
    & .width-240px {
      width: 240px;
    }
    & .width-250px {
      width: 250px;
    }
    & .width-260px {
      width: 260px;
    }
    & .width-300px {
      width: 300px;
    }
    & .width-350px {
      width: 350px;
    }

    & .height-120px {
      height: 120px;
    }

    & .height-100px {
      height: 100px;
    }

    & .height-80px {
      height: 80px;
    }

    & .height-20px {
      height: 20px;
    }

    & &__static-image {
      position: absolute;
      top: 20px;
      left: 40x;
      width: 680px;
      height: 1052px;
      z-index: 0;
    }

    & .wagon-number{
      position: absolute;
      top: 95px;
      left: 90px;
      z-index: 3;
    }

    & .order-number{
      position: absolute;
      top: 95px;
      left: 310px;
      z-index: 3;
    }

    & .workshop-code{
      position: absolute;
      top: 95px;
      left: 530px;
      z-index: 3;
    }

    & .keeper-ecm{
      position: absolute;
      top: 130px;
      left: 90px;
      z-index: 3;
    }

    & .customer-number{
      position: absolute;
      top: 130px;
      left: 310px;
      z-index: 3;
    }

    & .created-on{
      position: absolute;
      top: 130px;
      left: 530px;
      z-index: 3;
    }


    & .arival-date{
      position: absolute;
      top: 210px;
      left: 90px;
      z-index: 3;
    }

    & .departure-date{
      position: absolute;
      top: 210px;
      left: 310px;
      z-index: 3;
    }

    & .maintenance-label{
      position: absolute;
      top: 210px;
      left: 530px;
      z-index: 3;
      font-size: 12px;
    }

    & .footer-email{
      position: absolute;
      top: 1045px;
      left: 90px;
      z-index: 3;
    }

    & .footer-phone{
      position: absolute;
      top: 1045px;
      left: 310px;
      z-index: 3;
    }

    & .footer-name{
      position: absolute;
      top: 1045px;
      left: 530px;
      z-index: 3;
    }



    & .brake-type{
      position: absolute;
      top: 735px;
      left: 90px;
      z-index: 3;
    }

    & .distributor-valve-type{
      position: absolute;
      top: 735px;
      left: 260px;
      z-index: 3;
    }
    & .brake-cylinder{
      position: absolute;
      top: 735px;
      left: 425px;
      z-index: 3;
    }
    & .slack-adjuster{
      position: absolute;
      top: 735px;
      left: 590px;
      z-index: 3;
    }

    & .other-information{
      position: absolute;
      top: 825px;
      left: 90px;
      z-index: 3;
    }

  }
}
