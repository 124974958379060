.c-form-group {
  padding: 5px;

  &--flex {
    display: flex;
    justify-content: space-between;

    & label{
      display: inline-block;
      height: 100%;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  & label {
    margin-right: 10px;
  }
}