.c-date-input {
  position: relative;

  &* {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently */
  }

  .c-date-input__input-wrapper {}

  .c-date-input__input-wrapper input:focus {
    outline: none;
  }

  .c-date-input__input-wrapper input {
    text-align: center;
    letter-spacing: 2px;
    cursor: pointer;
    //padding-right: 40px;  //TODO: Add calendar icon
    background-image: url("../../assets/icons/date.svg");
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
  }

  @media print {
    .c-date-input__input-wrapper input {
      border: none !important;
    }
  }

  .c-date-input__picker {
    float: left;
    position: fixed;
    top: 100px;
    width: 300px;
    min-height: 350px;
    background: #fff;
    box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.2);
    border: 2px solid $color--dark;
    border-radius: 4px;
    overflow: hidden;
    padding: 0px 5px;
    z-index: 100;


    @include breakpoint("md") {
      position: absolute;
      top: auto;

      &--above {
        bottom: 100%;
      }

      &--over {
        position: fixed;
        top: 70px;
      }
    }
  }

  .c-date-input__header {
    width: 100%;
    height: 53px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .c-date-input__body {
    float: left;
    width: 100%;
  }

  /**
 * Controls
 */

  .c-date-input__button {
    width: 40px;
    height: 100%;
    box-sizing: border-box;
    position: relative;
  }

  .c-date-input__button-inner:hover>span {
    border-color: #555 !important;
  }

  .c-date-input__button-inner:hover {
    cursor: pointer;
    background: #eee;
  }

  .c-date-input__button-inner {
    float: left;
    height: 35px;
    width: 35px;
    background: #f4f4f4;
    border-radius: 100%;
    line-height: 35px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -17px;
    margin-top: -17px;
  }

  .mdpchbi-right-arrows:after,
  .mdpchbi-left-arrows:after,
  .mdpchbi-right-arrow,
  .mdpchbi-right-arrows,
  .mdpchbi-left-arrow,
  .mdpchbi-left-arrows {
    display: block;
    float: left;
    width: 6px;
    height: 6px;
    border-left: 2px solid #888;
    border-bottom: 2px solid #888;
    position: absolute;
  }

  .mdpchbi-right-arrow,
  .mdpchbi-right-arrows,
  .mdpchbi-left-arrow,
  .mdpchbi-left-arrows {
    transform: rotate(45deg);
    left: 50%;
    top: 50%;
    margin-left: -2px;
    margin-top: -4px;
  }

  .mdpchbi-right-arrows,
  .mdpchbi-right-arrow {
    transform: rotate(225deg);
    margin-left: -4px;
  }

  .mdpchbi-right-arrows:after,
  .mdpchbi-left-arrows:after {
    content: "";
  }

  .mdpchbi-left-arrows {
    margin-left: -5px;
  }

  .mdpchbi-right-arrows {
    margin-left: -2px;
  }

  .mdpchbi-right-arrows:after,
  .mdpchbi-left-arrows:after {
    left: 3px;
    top: -5px;
  }

  .c-container {
    float: left;
    width: 120px;
    height: 100%;
  }

  .mdpchc-year {
    float: left;
    width: 100%;
    height: 30px;
    font-size: 27px;
    color: #666;
    font-weight: 200px;
    text-align: center;
  }

  .mdpchc-month {
    float: left;
    width: 100%;
    height: 15px;
    font-size: 13px;
    color: #666;
    font-weight: 200px;
    text-align: center;
  }

  /**
 *  Calendar
 */
  .cc-month,
  .c-calendar__header,
  .c-calendar__day-name,
  .c-calendar__body,
  .c-date-input__day span,
  .c-date-input__day,
  .c-date-input__day-container,
  .c-calendar {
    position: relative;
    display: block;
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .c-calendar {
    width: 100%;
    height: 100%;
  }

  .cc-month {
    height: 30px;
    width: 100%;
    font-family: Roboto;
    font-size: 16px;
    line-height: 30px;
    color: #666;
  }

  .c-calendar__header {
    height: 30px;
    width: 100%;
    margin-top: 10px;
  }

  .c-calendar__day-name {
    width: 14.285%;
    height: 30px;
    line-height: 30px;
    font-weight: 700;
    color: #666;
    font-size: 9px;
    text-align: center;
  }

  .c-calendar__body {
    height: 270px;
    width: 100%;
  }

  .c-date-input__day-container {
    width: 14.285%;
    height: 16.6666%;
  }

  .c-date-input__day {
    width: 100%;
    height: 100%;
    font-size: 12px;
    font-weight: 300;
    color: #444;
    text-align: center;
  }

  .c-date-input__day span {
    width: 100%;
    height: 100%;
    font-size: 12px;
    font-weight: 300;
    color: #444;
  }

  .c-date-input__day span:hover {
    cursor: pointer;
    background: #eee;
  }

  .c-date-input__day span {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    left: 50%;
    top: 50%;
    font-weight: 400;
    border-radius: 100%;
    line-height: 30px;
  }

  .c-date-input__day-container.disabled {
    pointer-events: none;
  }

  .c-date-input__day-container.disabled .c-date-input__day span {
    color: #ddd;
  }

  .c-date-input__day-container.disabled .c-date-input__day span {
    background: #fff !important;
  }

  .c-date-input__day-container.highlight .c-date-input__day span {
    background: #efdbca;
  }

  .c-date-input__day-container.highlight-green .c-date-input__day span {
    background: #d4e2cb;
  }
}