/*----------------------------------------BREAKPOINTS----------------------------------------*/
$breakpoints: ('sm': (min-width: 767px),
    'md': (min-width: 992px),
    'lg': (min-width: 1200px)) !default;

/*----------------------------------------COLORS----------------------------------------*/

$color--base: #eff4f3;
$color--background: #dbf5f0;
$color--primary: #37beb0;
$color--secondary: #a4e5e0;
$color--dark: #0c6170;
$color--darker: #062f36;
$color--dark--text: #072d33;
$color--light--text: #f0faf8;

$color--input-bg: #dfecf1;
$color--input-border: #688ea0;

$color--success: #2ecc70;

$color--light: #b3d4e0;
$color--shade: #add8e6;

/*----------------------------------------INDICATORS COLORS----------------------------------------*/

$indicatorGreen: #2ecc71;
$indicatorRed: #e74c3c;
$indicatorBlue: #3498db;
$indicatorYellow: #f1c40f;
$indicatorGrey: #95a5a6;

/*----------------------------------------PADDINGS----------------------------------------*/

$padding-small: 5px;
$padding-medium: 10px;
$padding-large: 20px;

/*----------------------------------------FONT-WEIGHT----------------------------------------*/
$font-light: 300;
$font-regular: 400;
$font-semibold: 600;
$font-bold: 700;